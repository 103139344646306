import React from "react";
import styled from "@emotion/styled";

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import { Template } from "@narative/gatsby-theme-novela/src/types";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import Body from "../components/Body";

const Header = styled.h2`
  font-size: 2em;
`;

const MainDiv = styled(Body)`
  ${mediaqueries.desktop_medium_up`
    font-size: 1.33em;
  `}

  ${mediaqueries.tablet`
    font-size: 1em;
  `}

  ${mediaqueries.phablet`
    font-size: 1em;
  `}
`;

const Div = ({ header, text, children }) => {
  return (
    <div style={{ margin: "20px 0px" }}>
      {header ? <h2>{header}</h2> : null}
      {children}
    </div>
  );
};

const NowPage: Template = ({ location, pageContext }) => {
  return (
    <Layout>
      <SEO pathname={location.pathname} />
      <Section>
        <MainDiv>
          <Header>
            What am I doing <a href="https://nownownow.com/about">now</a>
          </Header>
          <Div>
            Since February 2022 I've fled Russia due to ongoing war. I'm
            currently based in Turkey.
          </Div>
          <Div>
            I've decided that my next project will be a simple clicker game made
            with Godot. It is time that I finally finished and deployed a game,
            even a simple one.
          </Div>
          <Div>
            I am still trying to finish reading Designing Data Intensive
            Applications.
          </Div>
          <Div>
            I am also building my personal web presence and brand. This blog
            is a part of it.
          </Div>
        </MainDiv>
      </Section>
    </Layout>
  );
};

export default NowPage;
